import { Container } from './styles';

interface IProps {
  name: string;
  barcode: string;
  price: number;
  quantity: number;
  removeProduct: (barcode: string) => void;
}

const ListProduct: React.FC<IProps> = ({
  name,
  barcode,
  quantity,
  price,
  removeProduct,
}: IProps) => {
  return (
    <Container>
      <>
        <b>{name}</b>
        <b>{quantity}</b>
        <b>{price}</b>
        <b>{Number(price) * Number(quantity)}</b>
        <button type="button" onClick={() => removeProduct(barcode)}>
          X
        </button>
      </>
    </Container>
  );
};

export default ListProduct;
