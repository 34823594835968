/* eslint-disable no-alert */
/* eslint-disable dot-notation */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MdOutlineRemoveShoppingCart } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  Cart,
  CartTitle,
  CartVoid,
  Container,
  Content,
  TotalContainer,
} from './styles';
import { Button, Input, ListProduct } from '../../components';
import productService from '../../services/productService';
import saleService from '../../services/saleService';
import { Menu } from '../Login/styles';
import Logotipo from '../../assets/logo.png';

const Vendas: React.FC = () => {
  const [dataProducts, setDataProducts] = useState<
    {
      name: string;
      price: number;
      barcode: string;
    }[]
  >([]);
  const [productInput, setProductInput] = useState('');
  const [quantityInput, setQuantityInput] = useState('');
  const [total, setTotal] = useState(0);
  const [dataCart, setDataCart] = useState<any>([]);
  const [customer, setCustomer] = useState('');

  const getProducts = async () => {
    try {
      const response = await productService.getAllProducts();
      setDataProducts(response);
    } catch {
      alert('recarregue a pagina');
    }
  };

  const sell = async () => {
    if (dataCart.length && customer) {
      const payload = {
        cardNumber: customer,
        products: dataCart.map((productElement: any) => ({
          productId: productElement.product['_id'],
          quantity: productElement.quantity,
        })),
      };
      try {
        await saleService.sendSale(payload);

        setDataCart([]);
        setTotal(0);
        alert('Venda Efetuada');
      } catch (error: any) {
        alert(error.response.data.message);
      }
    }
  };

  const updateTotal = (dataCart: any) => {
    if (!dataCart.length) {
      setTotal(0);
      return;
    }

    const total = dataCart
      .map(
        (productElement: any) =>
          Number(productElement.quantity) *
          Number(productElement.product.price),
      )
      .reduce((a: number, b: number) => a + b);

    setTotal(total);
  };

  const setProductInCart = () => {
    const product = dataProducts.find(e => e.barcode === productInput);

    if (!product) {
      alert('Produto não encontrado');
      return;
    }

    const tempCart = [...dataCart];

    const cartIndexOfProduct = tempCart.findIndex(
      (productElement: any) => productElement.product.barcode === productInput,
    );

    if (cartIndexOfProduct >= 0) {
      tempCart[cartIndexOfProduct].quantity =
        Number(tempCart[cartIndexOfProduct].quantity) + Number(quantityInput);

      setDataCart(tempCart);

      setProductInput('');
      setQuantityInput('');
      updateTotal(tempCart);
      return;
    }

    tempCart.push({ product, quantity: Number(quantityInput) });
    setDataCart(tempCart);

    setProductInput('');
    setQuantityInput('');
    updateTotal(tempCart);
  };

  const removeProductInCart = (barcode: string) => {
    const tempCart = [...dataCart];

    const cartIndexOfProduct = tempCart.findIndex(
      (productElement: any) => productElement.product.barcode === barcode,
    );

    if (cartIndexOfProduct >= 0) {
      tempCart.splice(cartIndexOfProduct, 1);
      setDataCart(tempCart);
      console.log('tempCart', tempCart);
      updateTotal(tempCart);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      <Menu>
        <img src={Logotipo} alt="Logo" />
        <div>
          <Link to="/home">Vendas</Link>
          <Link to="/pontos">Pontos</Link>
        </div>
      </Menu>
      <Container>
        <Content>
          <Input
            placeholder="Cod. de barras"
            label="Produto"
            value={productInput}
            onChange={e => setProductInput(e.target.value)}
          />
          <Input
            placeholder=""
            label="Quantidade"
            value={quantityInput}
            onChange={e => setQuantityInput(e.target.value)}
            onKeyUp={(e: any) => {
              if (e.key === 'Enter') setProductInCart();
            }}
          />
          {dataCart.length > 0 ? (
            <Cart>
              <CartTitle>
                <b>Descrição:</b>
                <b>Quantidade:</b>
                <b>valor:</b>
                <b>Total:</b>
                <b>Remover</b>
              </CartTitle>
              {dataCart.map((e: any) => {
                return (
                  <ListProduct
                    name={e.product.name}
                    barcode={e.product.barcode}
                    quantity={e.quantity}
                    price={e.product.price}
                    removeProduct={removeProductInCart}
                  />
                );
              })}
            </Cart>
          ) : (
            <CartVoid>
              <MdOutlineRemoveShoppingCart />
              <b>Carrinho Vazio</b>
            </CartVoid>
          )}
          <TotalContainer>
            <h1>Total</h1>
            <h1>{total}D</h1>
          </TotalContainer>
          <Input
            placeholder="Nº do cartão"
            label="Aluno"
            value={customer}
            onChange={e => setCustomer(e.target.value)}
          />
          <Button type="submit" onClick={sell}>
            Vender
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default Vendas;
