import api from './api';

class SaleService {
  async sendSale(payload: any): Promise<any> {
    const response = await api.post(`/sales`, payload);

    return response.data;
  }
}

export default new SaleService();
