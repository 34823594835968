import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  background: #0f0f0f; /* fallback for old browsers */
`;

export const PageLogin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Img = styled.div`
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  svg {
    width: 50%;
    height: fit-content;
  }
`;

export const Content = styled.div`
  border-radius: 10px;
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 600px;
  width: 100%;
  padding: 40px;
  height: min-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;

  input {
    margin: 10px 0;
  }
  button {
    margin: 10px 0;
  }

  @media (max-width: 600px) {
    width: 100vw;
    border-radius: 0;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  padding: 20px 0;
  color: #ffffff;
`;

export const A = styled.a`
  margin-top: 10px;
  cursor: pointer;
`;

export const Menu = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  background: #0f0f0f;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 5px 30px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #fff;
    margin-left: 30px;
    padding: 7px 10px;
    height: 60%;
    background: #0f0f0f;
    border-radius: 10px;
    transition: background-color 0.3s ease-out;
  }

  a:hover {
    color: #0f0f0f;
    background: #fff;
  }

  img {
    height: 80%;
  }
`;
